import _ from 'lodash';
import { AppRoles, MultipleUsersData, User, UserRole } from './types';

const transformRoleById = (roleId: any): UserRole => {
  switch (roleId) {
    case 1:
      return UserRole.Admin;
    case 2:
      return UserRole.User;
    case 3:
      return UserRole.NoAccess;
    default:
      return UserRole.NoAccess;
  }
};

const transformRoleIdByRole = (role: UserRole | null): number | null => {
  switch (role) {
    case UserRole.Admin:
      return 1;
    case UserRole.User:
      return 2;
    case UserRole.NoAccess:
      return 3;
    default:
      return null;
  }
};

export const transformAppRoles = (data: any): AppRoles => {
  const userRole = transformRoleById(data.role_id);

  return {
    name: data.name ?? '',
    role: userRole,
  };
};

export const transformRequestAppRoles = (data: AppRoles): any => {
  const resolvedRequestRole = transformRoleIdByRole(data.role) ?? null;

  return {
    name: data.name ?? '',
    role_id: resolvedRequestRole,
  };
};

export const transformTotp = (data: any) => {
  if (data.credentials !== undefined) {
    return data.credentials.totp !== undefined;
  }
  return undefined;
};

export const transformUser = (response: any): User => {
  return {
    id: response.id ?? '',
    app_roles: response.traits.app_roles ? response.traits.app_roles.map(transformAppRoles) : [],
    email: response.traits.email ?? '',
    name: response.traits.name ?? '',
    preferredUsername: response.preferredUsername ?? '',
    status: response.state ?? '',
    totp: transformTotp(response),
    tags: response.stackspin_data.tags ?? '',
    admin: response.stackspin_data.stackspin_admin ?? '',
    meta: response.metadata_admin ?? '',
  };
};

export const transformRequestUser = (data: Pick<User, 'app_roles' | 'name' | 'email'>) => {
  return {
    app_roles: data.app_roles.map(transformRequestAppRoles),
    email: data.email ?? '',
    name: data.name ?? '',
  };
};

export const transformUpdateMultipleUsers = (response: any): any => {
  return {
    success: response.success,
    existing: response.existing,
    failed: response.failed,
  };
};

export const transformRequestUpdateMultipleUsers = (data: any) => {
  return {
    users: _.map(data, (user: Pick<User, 'email' | 'id' | 'app_roles'>) => {
      return { email: user.email ?? '', id: user.id ?? '', app_roles: user.app_roles.map(transformRequestAppRoles) };
    }),
  };
};

const extractUsersFromCsv = (csvData: string) => {
  const csvRows = csvData.split('\n');

  return _.map(csvRows, (row) => {
    const values = row.split(',');
    const email = values[0].trim();
    const name = !_.isNil(values[1]) ? values[1].trim() : '';
    return { email, name, app_roles: [] };
  });
};

export const transformRequestMultipleUsers = (data: MultipleUsersData) => {
  const batchUsers = extractUsersFromCsv(data.csvUserData);
  return {
    users: _.map(batchUsers, (user) =>
      transformRequestUser({ app_roles: data.appRoles, name: user.name, email: user.email } as User),
    ),
  };
};

export const transformBatchResponse = (response: any): any => {
  return {
    success: response.success,
    existing: response.existing,
    failed: response.failed,
  };
};

export const transformRecoveryLink = (response: any): string => {
  return response.recovery_link;
};

export const transformResetTotpById = (response: any): any => {
  return {
    success: response.success,
    existing: response.existing,
    failed: response.failed,
  };
};
