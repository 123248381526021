import React from 'react';
import { Helmet } from 'react-helmet';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { useAuth } from 'src/services/auth';
import { Dashboard, Users, Login, Apps, AppSingle, ResourcesDashboard } from './modules';
import { Layout } from './components';
import { LoginCallback } from './modules/login/LoginCallback';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function App() {
  const { authToken, currentUser, isAdmin } = useAuth();

  const redirectToLogin = !authToken || !currentUser?.app_roles;

  const ProtectedRoute = () => {
    return isAdmin ? <Outlet /> : <Navigate to="/dashboard" />;
  };

  return (
    <>
      <Helmet>
        <title>Stackspin</title>
        <meta name="description" content="Stackspin" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>

      <div className="app bg-gray-50 min-h-screen flex flex-col">
        {redirectToLogin ? (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/login-callback" element={<LoginCallback />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        ) : (
          <Layout>
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/users" element={<ProtectedRoute />}>
                <Route index element={<Users />} />
              </Route>
              <Route path="/apps" element={<ProtectedRoute />}>
                <Route path=":slug" element={<AppSingle />} />
                <Route index element={<Apps />} />
              </Route>
              <Route path="/resources" element={<ProtectedRoute />}>
                <Route index element={<ResourcesDashboard />} />
              </Route>
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>
          </Layout>
        )}

        {/* Place to load notifications */}
        <div
          aria-live="assertive"
          className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
        >
          <div className="w-full flex flex-col items-center space-y-4 sm:items-end" />
        </div>
      </div>
      <Toaster />
    </>
  );
}

export default App;
