export interface User {
  id: string;
  app_roles: AppRoles[];
  email: string;
  name: string;
  preferredUsername: string;
  status: string;
  totp?: boolean;
  tags?: [];
  admin?: boolean;
  meta?: [];
}

export interface FormUser extends User {
  password?: string;
  confirmPassword?: string;
}

export enum UserRole {
  NoAccess = 'no_access',
  Admin = 'admin',
  User = 'user',
}

export enum NoChange {
  NoChange = 'no_change',
}

export interface AppRoles {
  name: string | null;
  role: UserRole | null;
}

export interface MultiEditAppRoles {
  name: string | null;
  role: UserRole | NoChange | null;
}

export interface UserApiRequest {
  id: number | null;
  email: string;
  name: string;
  status: string;
}

export interface MultipleUsersData {
  csvUserData: string;
  appRoles: AppRoles[];
}

export interface MultiEditUser {
  userIds: string[];
  userEmails: string[];
  userNames: string[];
  app_roles: MultiEditAppRoles[];
  status: string;
}
